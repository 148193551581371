import Head from 'next/head';
import * as React from 'react';

function HellWorld() {
  return (
    <>
      <Head>
        <title>Paperpal</title>
      </Head>
      <h1>Hello, world!</h1>
    </>
  );
}

export default HellWorld;
